import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';

const CookieDeclarationContainer = styled.div`
  .CookieDeclarationDialogText {
    font-family: Catamaran, sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const CookieDeclaration = () => {
  return (
    <>
      <CookieDeclarationContainer id='insertCookieDeclaration'>
        <p>
          You can find out more information about my privacy policy <Link to='/privacy'>here</Link>
        </p>
      </CookieDeclarationContainer>
    </>
  );
};

export default () => {
  return (
    <Layout>
      <SEO title='Cookie Declaration' pathname='/cookie-policy/' />
      <div style={{ margin: '0 auto', maxWidth: '800px' }}>
        <h2>Cookie Declaration</h2>
        <CookieDeclaration />
        <p>
          However, if you have come here expecting to see what kind of cookies I have then I'm sorry
          but I'm going to have to disappoint.{' '}
        </p>

        <p>
          I have decided that this website is not the best place to be storing cookies (I'm not a
          huge fan of sharing cookies), but for future reference triple chocolate cookies are my
          favourite.
        </p>
      </div>
    </Layout>
  );
};
